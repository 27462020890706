import React, { useContext, useEffect, useRef, createRef } from 'react'
import { graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Footer from '../components/Footer'
import {
  Box,
  Flex,
  Container,
  Button,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { spacing, breakpoints, colors, hexToRGB, lumVal } from "../theme/themeAlopex"
import { H2, H3, H4 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
// SVG Components
import MadeInUSA from '../components/WhyIcons/MadeInUSA'
import Certified from '../components/WhyIcons/Certified'
import DigitalArchitects from '../components/WhyIcons/DigitalArchitects'
import Local from '../components/WhyIcons/Local'
import AppIcons from '../components/WhyIcons/AppIcons'
import Vetted from '../components/WhyIcons/Vetted'
import DesDev from '../components/WhyIcons/DesDev'
import Flexible from '../components/WhyIcons/Flexible'
import IncredibleSEO from '../components/WhyIcons/IncredibleSEO'
import TalentTriangle from '../components/TalentTriangle'
// Clients
// import Clients from '../components/Clients'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Testimonials from '../components/Testimonials'
import Monitoring from '../components/Services/Tiers/Monitoring'
import Enhanced from '../components/Services/Tiers/Enhanced'
import Premium from '../components/Services/Tiers/Premium'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const  ServicePost = ({ data }) => {
  const { page } = data
  const { color } = useContext(ColorContext)
  const refHeader = useRef(null)
  const refVid = useRef(null)

  // Slider settings
  const sliderSettings = {
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    infinite: true,
    adaptiveHeight: false,
    slidesToShow: (!!page?.listTalent && page.listTalent.length <= 6) ? page.listTalent.length : 6,
    slidesToScroll: 1,
    waitForAnimate: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: (!!page?.listTalent && page.listTalent.length <= 4) ?  page.listTalent.length : 4
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let canvasBody = document.getElementById('canvas')
      if (canvasBody !== null) {
        // Set canvasBody height to match header area
        canvasBody.height = refHeader.current.offsetHeight

        const opts = { 
          particleColor: "rgba(255,255,255, 0.7)",
          lineColor: "rgba(255,255,255, 0.7)",
          particleAmount: 40,
          defaultSpeed: 0.1,
          variantSpeed: 1,
          defaultRadius: 2,
          variantRadius: 2,
          linkRadius: 250,
        };
    
        const Particle = class { 
          constructor(x, y) {
            this.x = Math.random() * w; 
            this.y = Math.random() * h;
            this.speed = opts.defaultSpeed + Math.random() * opts.variantSpeed; 
            this.directionAngle = Math.floor(Math.random() * 360); 
            this.color = opts.particleColor;
            this.radius = opts.defaultRadius + Math.random() * opts.variantRadius; 
            this.vector = {
              x: Math.cos(this.directionAngle) * this.speed,
              y: Math.sin(this.directionAngle) * this.speed
            };
          }
          update = function(){ 
            this.border(); 
            this.x += this.vector.x; 
            this.y += this.vector.y; 
          };
          border = function(){ 
            if (this.x >= w || this.x <= 0) { 
              this.vector.x *= -1;
            }
            if (this.y >= h - 12 || this.y <= 0) {
              this.vector.y *= -1;
            }
            if (this.x > w) this.x = w;
            if (this.y > h) this.y = h;
            if (this.x < 0) this.x = 0;
            if (this.y < 0) this.y = 0;	
          };
          draw = function(){ 
            drawArea.beginPath();
            drawArea.arc(this.x, this.y, this.radius, 0, Math.PI*2);
            drawArea.closePath();
            drawArea.fillStyle = this.color;
            drawArea.fill();
          };
        };
    
        const resizeReset = () => {
          canvasBody.height = refHeader.current.offsetHeight
          w = canvasBody.width = window.innerWidth
          h = canvasBody.offsetHeight
        }
        
        const deBouncer = () => {
          clearTimeout(tid);
          tid = setTimeout(function() {
            resizeReset();
          }, delay);
        };
        
        let checkDistance = (x1, y1, x2, y2) => { 
          return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        };
        
        let linkPoints = (point1, hubs) => { 
          for (let i = 0; i < hubs.length; i++) {
            let distance = checkDistance(point1.x, point1.y, hubs[i].x, hubs[i].y);
            let opacity = 1 - distance / opts.linkRadius;
            if (opacity > 0) { 
              drawArea.lineWidth = 0.5;
              drawArea.strokeStyle = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
              drawArea.beginPath();
              drawArea.moveTo(point1.x, point1.y);
              drawArea.lineTo(hubs[i].x, hubs[i].y);
              drawArea.closePath();
              drawArea.stroke();
            }
          }
        }
        
        const setup = () => { 
          resizeReset();
          for (let i = 0; i < opts.particleAmount; i++){
            particles.push( new Particle() );
          }
          window.requestAnimationFrame(loop);
        }
        
        const loop = () => { 
          window.requestAnimationFrame(loop);
          drawArea.clearRect(0,0,w,h);
          for (let i = 0; i < particles.length; i++){
            particles[i].update();
            particles[i].draw();
          }
          for (let i = 0; i < particles.length; i++){
            linkPoints(particles[i], particles);
          }
        }
        
        window.addEventListener("resize", function(){
          resizeReset();
          deBouncer();
        });
        let particles = [];
        // let canvasBody = document.getElementById("canvas");
        let w = canvasBody.width = window.innerWidth
        let h = canvasBody.offsetHeight
        // canvasBody.parentElement.style.height = "400px";
        let drawArea = canvasBody.getContext("2d");
        let delay = 200, tid,
        rgb = opts.lineColor.match(/\d+/g);
        resizeReset();
        setup();
      }
    }

    // Listen for end of video and reload for persistent video poster (thumbnail)
    if (refVid.current) {
      refVid.current.addEventListener('ended', () => {
        refVid.current.load()
      })
    }
  }, [])

  // Slide in on scroll
  const gsapSlideIn = (el, left = true, speed = 1, distance = 50) => {
    gsap.from(el, {
      scrollTrigger: {
        trigger: el,
        start: 'middle 85%',
        end: 'middle 5%',
        // toggleActions: "play reverse play reverse"
        toggleActions: "play reverse play reverse"
      },
      duration: speed,
      x: left ? (distance * -1) : distance,
      opacity: 0,
    })
  }

  // Reasons refs
  const refReasons = useRef([])
  for (let i = 0; i < page.reasons.length; i++) {
    refReasons.current[i] = createRef()
  }
  // Sub serbices refs
  const refServices = useRef([])
  for (let i = 0; i < page.listServices.length; i++) {
    refServices.current[i] = createRef()
  }
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      for(let i = 0; i < refReasons.current.length; i++) {
        // gsapSlideIn(refReasons.current[i].current, i % 2 === 0 ? true : false, 0.6)
        gsapSlideIn(refReasons.current[i].current, true, 0.3)
      }
      for(let i = 0; i < refServices.current.length; i++) {
        // gsapSlideIn(refServices.current[i].current, i % 2 === 0 ? true : false, 0.6)
        gsapSlideIn(refServices.current[i].current, true, 0.3)
      }

      // Show video controls on hover
      for (const video of document.querySelectorAll('video')) {
        video.controls = false
        video.addEventListener('mouseover', () => { video.controls = 'controls' })
        video.addEventListener('mouseout', () => { video.controls = false })
      }
    }
  }, [])

  // Get reasons icon component
  const getReasonIcon = (icon, style = {}) => {
    switch (icon) {
      case 'miusa':
        return <MadeInUSA style={style} />
      case 'certified':
        return <Certified style={style} />
      case 'digital':
        return <DigitalArchitects style={style} />
      case 'desdev':
        return <DesDev style={style} />
      case 'seo':
        return <IncredibleSEO style={style} />
      case 'flexible':
        return <Flexible style={style} />
      case 'local':
        return <Local style={style} />
      case 'vetted':
        return <Vetted style={style} />
      case 'appicons':
        return <AppIcons style={style} />
      default:
        return <MadeInUSA style={style} />
    }
  }

    const schema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": 
      page.listFAQ.map((faq, i) => {
        return i = 0 ? i ({
          "@type": "Question",
          "name":  faq.faqQuestion,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.faqAnswer
          }
        }
        
        ) : ({
          "@type": "Question",
          "name": faq.faqQuestion,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.faqAnswer
          }
        })
      })
    };
    
  

  return (
    <Layout>
    {(!page.listFAQ ? 
      <SEO title={page?.seoFields?.seoTitle} description={page?.seoFields?.seoDesc} />
      :
      <SEO title={page?.seoFields?.seoTitle} description={page.seoFields?.seoDesc} schemaMarkUp={schema}/>
    )}
      <Box
        sx={{
          'ul': {
            marginBottom: '50px'
          },
          'li': {
            marginBottom: '10px'
          }
        }}
      >
        {/* HEADER VIDEO */}
        <Box background={`linear-gradient(to right, ${hexToRGB(color.alpha, 0.85)}, ${hexToRGB(color.bravo, 0.85)})`} marginTop="45px" mb={[spacing.groups, null, spacing.section]}>
          {(!!page.video || !!page.serviceVideoUpload) ? 
            (
              <Container maxWidth={breakpoints.xl} padding={['60px 0', null, null, '60px 1rem']}>
                <Box
                  position='relative'
                  pb="56.25%"
                  height="0"
                  overflow="hidden"
                  boxShadow={`
                    0.7px 0.7px 0.9px -3px rgba(0, 0, 0, 0.034),
                    1.7px 1.7px 2.1px -3px rgba(0, 0, 0, 0.048),
                    3.1px 3.1px 4px -3px rgba(0, 0, 0, 0.06),
                    5.6px 5.6px 7.1px -3px rgba(0, 0, 0, 0.072),
                    10.4px 10.4px 13.4px -3px rgba(0, 0, 0, 0.086),
                    25px 25px 32px -3px rgba(0, 0, 0, 0.12)
                  `}
                  borderRadius="4px"
                  sx={{
                    'iframe, object, embed': {
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    },
                    /* 'video::cue': {
                      backgroundImage: `linear-gradient(to right, ${color.alpha}, ${color.bravo})`,
                      fontFamily: 'Abel'
                    } */
                  }}
                >
                  {!!page.video && !page.serviceVideoUpload &&
                    <iframe
                      width="100%"
                      height="100%"
                      src={`//www.youtube.com/embed/${page.video}/?rel=0&modestbranding=1&autohide=1`}
                      title="YouTube video player"
                      frameBorder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  }
                  {!!page.serviceVideoUpload &&
                    <video
                      ref={refVid}
                      width="100%"
                      height="100%"
                      poster={page.vidThumb !== '' ? page?.vidThumb.replace('/static', '') : '/services/2024/video-poster.webp'}
                    >
                      <source src={`https://alopex.mo.cloudinary.net${page.serviceVideoUpload.replace('/static', '')}?type=auto-upload`} type="video/mp4"/>
                      {!!page?.serviceVideoCCTrack &&
                        <track
                          kind="captions"
                          srcLang="en"
                          src={page.serviceVideoCCTrack.replace('/static', '')}
                        />
                      }
                      Your browser does not support the video tag.
                    </video>
                  }
                </Box>


                <Container
                  maxWidth={breakpoints.xl}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection={['column', null, null, 'row']}
                  textAlign={['center', null, null, 'left']}
                  color={colors.brand.white}
                  pt="60px"
                  sx={{
                    'a button': {
                      color: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo,
                      transition: 'color 0.2s ease-in-out, background-color 0.2s ease-in-out',
                      '&:hover': {
                        color: '#ffffff',
                        backgroundColor: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo,
                      }
                    }
                  }}
                >
                  <H3 mb={['10px', null, null, 0]} lineHeight="2">Want to start right away?</H3>
                  <Box marginLeft={[null, null, null, '20px']}>
                    <a href="sms:907-952-5953">
                      <Button variant="buttonCTA" style={{height: 'auto', textTransform: 'none'}}>Text</Button>
                    </a>
                    <a href="tel:907-952-5953">
                      <Button variant="buttonCTA" style={{height: 'auto', textTransform: 'none'}}>Call</Button>
                    </a>
                  </Box>
                </Container>

              </Container>
            ) : (
              <Box
                ref={refHeader}
                height="80vh"
                width="100%"
                position="relative"
              >
                <canvas
                  id="canvas"
                  width="100vw"
                  style={{
                    position: 'absolute',
                    maxWidth: '100%',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 20,
                  }}
                >
                  Testing
                </canvas>
                <Box
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)"
                  zIndex="30"
                  color="white"
                >
                  <ReactMarkdown remarkPlugins={[gfm]} children={page.hdrContent} />
                </Box>
              </Box>
            )
          }
        </Box>

        {/* INTRO MDX */}
        <Container maxWidth={breakpoints.lg} mb={[spacing.groups, null, spacing.section]}>
          <Box
            sx={{
              h1: {
                fontSize: '36px!important',
                '@media only screen and (min-width: 960px)': {
                  fontSize: '45px!important'
                }
              }
            }}
          >
            <ReactMarkdown remarkPlugins={[gfm]} children={page.mdxIntro} />
          </Box>
        </Container>

        {/* SUB SERVICES */}
        <Container maxWidth={breakpoints.lg} mb={[spacing.groups, null, spacing.section]}>
          <H2 mb={spacing.elements}>{page.ssHeading}</H2>
          <SimpleGrid columns={[1, null, 2]} gap={spacing.elements} sx={{p: { marginBottom: 0 }}}>
            {page.listServices.map((svc, i) => {
              return (
                <Box
                  key={`service-item-${i}`}
                  ref={refServices.current[i]}
                  sx={{
                    h3: {
                      background: `-webkit-linear-gradient(right, ${color.alpha}, ${color.bravo})`,
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      display: 'inline-block'
                    }
                  }}
                >
                  <ReactMarkdown remarkPlugins={[gfm]} children={svc.mdxService} />
                </Box>
              )
            })}
          </SimpleGrid>
        </Container>

        {/* CASE STUDY */}
        <Box mb={[spacing.groups, null, spacing.section]}>
          <BackgroundImage
            // fluid={!!page?.fields?.imageRel?.childImageSharp?.fluid ? page.fields.imageRel.childImageSharp.fluid : null}
            style={{
              width: '100%',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box 
              position="absolute"
              width="100%"
              height="100%"
              top="0"
              left="0"
              backgroundImage={`linear-gradient(to right, ${color.alpha}, ${color.bravo})`}
              opacity="0.64"
              sx={{
                h2: {
                  marginBottom: '0!important'
                }
              }}
            />
            <Container maxWidth={breakpoints.lg} position="relative" zIndex="1" color={colors.brand.white} padding={['150px 1rem 100px 1rem', null, '250px 0 200px 0']}>
              <ReactMarkdown remarkPlugins={[gfm]} children={page.mdxCS} />
            </Container>
          </BackgroundImage>
        </Box>

        {/* TESTIMONIALS */}
        {!!page?.listTesti && page.listTesti.length > 0 &&
          <Container maxWidth={breakpoints.lg} mb={[spacing.groups, null, spacing.section]}>
            <Testimonials testis={page.listTesti} />
          </Container>
        }

        {/* CALL TO ACTION */}
        <Box
          backgroundImage={`linear-gradient(to right, ${color.alpha}, ${color.bravo})`}
          p={`${spacing.elements} 0`}
          mb={[spacing.groups, null, spacing.section]}
        >
          <Container
            maxWidth={breakpoints.xl}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={['column', null, null, 'row']}
            textAlign={['center', null, null, 'left']}
            color={colors.brand.white}
            sx={{
              'a button': {
                color: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo,
                transition: 'color 0.2s ease-in-out, background-color 0.2s ease-in-out',
                '&:hover': {
                  color: '#ffffff',
                  backgroundColor: lumVal(color.alpha) < lumVal(color.bravo) ? color.alpha : color.bravo,
                }
              }
            }}
          >
            <H2 mb={['10px', null, null, 0]} lineHeight="1">{page.ctaCopy}</H2>
            <Box marginLeft={[null, null, null, '20px']}>
              <a href="sms:907-952-5953">
                <Button variant="buttonCTA" style={{height: 'auto', textTransform: 'none'}}>Text</Button>
              </a>
              <a href="tel:907-952-5953">
                <Button variant="buttonCTA" style={{height: 'auto', textTransform: 'none'}}>Call</Button>
              </a>
            </Box>
          </Container>
        </Box>

        {/* SECOND MDX INTRO */}
        <Container maxWidth={breakpoints.lg} mb={page?.priceTiersShow ? spacing.elements : [spacing.groups, null, spacing.section]}>
          <Box
            mb={spacing.elements}
            sx={{
              'h2:not(:first-of-type)': {
                marginTop: '50px'
              }
            }}
          >
            <ReactMarkdown remarkPlugins={[gfm]} children={page.mdxSecond} />
          </Box>
        </Container>

        <Container maxWidth={breakpoints.xl} mb={[spacing.groups, null, spacing.section]}>
          {/* OPTIONAL PRICING TIERS */}
          {page?.priceTiersShow &&
            <SimpleGrid
              gap={spacing.icon}
              columns={[1, null, 3]}
              sx={{
                '.tierBox': {
                  backgroundColor: color.alpha,
                  borderRadius: '10px',
                  padding: '2px',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: `0 2px 10px ${hexToRGB(colors.brand.slate, 0.4)}`
                  },
                  '& .whiteBox': {
                    backgroundColor: hexToRGB('#ffffff', 0.95),
                    borderRadius: '8px',
                    padding: '50px 30px',
                    '.tierIcon': {
                      maxWidth: '90px',
                      color: color.alpha,
                      marginBottom: spacing.elements
                    },
                    '& h3': {
                      color: color.alpha,
                      marginBottom: spacing.icon
                    },
                    'ul': {
                      marginBottom: '0',
                      'li': {
                        '&:not(:last-of-type)': {
                          marginBottom: '20px'
                        },
                        '&::marker': {
                          color: color.alpha
                        }
                      }
                    }
                  },
                  '&.tb02': {
                    backgroundColor: color.bravo,
                    '& .tierIcon': {
                      color: color.bravo
                    },
                    'ul li::marker': {
                      color: color.bravo
                    },
                    '& h3': {
                      color: color.bravo
                    }
                  },
                  '&.tb03': {
                    backgroundImage: `linear-gradient(135deg, ${color.alpha}, ${color.bravo})`,
                    '& h3': {
                      color: color.alpha,
                      display: 'inline-block',
                      background: `-webkit-linear-gradient(0deg, ${color.alpha}, ${color.bravo})`,
                      'WebkitBackgroundClip': 'text',
                      'WebkitTextFillColor': 'transparent',
                      backgroundClip: 'text',
                      textFillColor: 'transparent'
                    }
                  },
                  'h4': {
                    textAlign: 'center',
                    padding: spacing.icon,
                    fontWeight: 'bold',
                    color: 'white',
                    fontSize: '22px'
                  }
                }
              }}
            >
              {page?.priceTiers.length > 0 && page?.priceTiers.map((tier, i) => {
                return (
                  <Box>
                    <Box className={`tierBox tb0${i+1}`}>
                      <Box className="whiteBox">
                        <Box className="tierIcon">
                          {i === 0 &&
                            <Monitoring />
                          }
                          {i === 1 &&
                            <Enhanced />
                          }
                          {i === 2 &&
                            <Premium stop1={color.alpha} stop2={color.bravo} />
                          }
                        </Box>
                        <H3>{tier.priceTierTitle}</H3>
                        <ReactMarkdown remarkPlugins={[gfm]} children={tier.priceTierMDX} />
                      </Box>
                      <H4>{tier.priceTierPrice}</H4>
                    </Box>
                  </Box>
                )
              })}
            </SimpleGrid>
          }
        </Container>

        {/* REASONS */}
        <Container maxWidth={breakpoints.lg} mb={[spacing.groups, null, spacing.section]}>
          <Box>
            {!!page?.reasons && page.reasons.map((reason, i) => {
              return (
                <Flex ref={refReasons.current[i]} flexDirection={['column', null, 'row']} mb={[spacing.elements, null, spacing.groups]} style={{gap: '35px'}}>
                  {getReasonIcon(reason?.icon, { width: '100%', height: 'auto', margin: '0 auto 0 auto', maxWidth: '225px', minWidth: '135px', maxHeight: '155px' })}
                  <Box textAlign={['center', null, 'left']}>
                    <H3>{reason?.title}</H3>
                    <Box as="p" mb="0">
                      {reason?.blurb}
                    </Box>
                  </Box>
                </Flex>
              )
            })}
          </Box>
        </Container>

        {/* CLIENTS */}
        {/* <Container maxWidth={breakpoints.lg} mb={[spacing.groups, null, spacing.section]}>
          <H2>Clients</H2>
          <Clients />
        </Container> */}

        {/* TALENTS */}
        <Box
          backgroundColor={hexToRGB(colors.brand.slate, 0.03)}
          py={[spacing.groups, null, spacing.section]}
          sx={{
            h3: {
              background: `-webkit-linear-gradient(right, ${color.alpha}, ${color.bravo})`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              color: color.bravo,
              display: 'inline-block'
            }
          }}
        >
          <Container maxWidth={breakpoints.lg}>
              <H2>Your team</H2>
                {/* <SimpleGrid
                columns={[1, null, null, 3]}
                gap={spacing.icon}
                }}
              ></SimpleGrid> */}
          </Container>

          <Slider {...sliderSettings}>
            {!!page?.listTalent && page.listTalent.map((tal, i) => {
              return (
                <Box padding="0 40px" key={`talent-key-${i}`}>
                  <AniLink paintDrip hex={color.alpha} to={`/talent/${tal.talent}`} style={{maxWidth: '300px', display: 'block', margin: '0 auto', width: '100%'}}>
                    <TalentTriangle talent={tal.talent} />
                  </AniLink>
                  <Box textAlign="center">
                    <AniLink paintDrip hex={color.alpha} to={`/talent/${tal.talent}`}>
                      <H3>{tal.talentName}</H3>
                    </AniLink>
                    <p>
                      {tal.talentBlurb}
                    </p>
                  </Box>
                </Box>
              )
            })}
          </Slider>
              
        </Box>

        {/* FAQ */}
        <Box
          backgroundColor={color.bravo}
          py={[spacing.groups, null, spacing.section]}
        >
          <Container maxWidth={breakpoints.lg}>
            {page.listFAQ !== null &&
              <>
                <H2 color="white">F.A.Q.</H2>
                <Accordion allowToggle="true">
                  {page.listFAQ.map((faq, i) => {
                    return (
                        <AccordionItem
                          key={`faq-item-${i}`}
                          borderTopWidth="0"
                          borderBottomWidth="1px"
                          sx={{
                            button: {
                              fontSize: 'inherit',
                              backgroundColor: 'transparent',
                              color: colors.brand.white,
                              padding: '1rem',
                              '&:focus': {
                                boxShadow: 'none',
                                background: 'none',
                              }
                            }
                          }}
                        >
                          <H3 marginBottom="0">
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                {faq.faqQuestion}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </H3>
                          <AccordionPanel pb={4}>
                            <Box as="p" color="white">
                              {faq.faqAnswer}
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                    )
                  })}
                </Accordion>
              </>
            }
          </Container>
        </Box>
      </Box>

      <Footer mode="bg01" fTitle={page.footerText} overlay />
    </Layout>
  ) 
}

export const query = graphql`
  query serviceQuery($id: String!) {
    page: serviceJson(id: { eq: $id }) {
      title
      hdrContent
      overlayColor
      video
      vidThumb
      serviceVideoUpload
      serviceVideoCCTrack
      mdxIntro
      ctaCopy
      mdxSecond
      priceTiersShow
      priceTiers {
        priceTierTitle
        priceTierMDX
        priceTierPrice
      }
      reasons {
        blurb
        icon
        title
      }
      csBG
      listTalent {
        talent
        talentName
        talentBlurb
      }
      mdxCS
      ssHeading
      listServices {
        mdxService
      }
      listFAQ {
        faqQuestion
        faqAnswer
      }
      listTesti {
        testiQuote
        testiAttribution
      }
      footerText
      seoFields {
        seoTitle
        seoDesc
      }
    }
  }
`

/* fields {
  imageRel {
    childImageSharp {
      fluid(maxWidth: 1200, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
} */

export default ServicePost